<template>
  <div class="pa-5">
    <v-card class="mx-auto pa-3">
      <v-card-title style="display: flex; flex-flow: row nowrap; align-items: flex-start; gap: 5%; white-space: nowrap">
        <h2 v-t="'Patterns.PatternsManagement'"></h2>
        <v-spacer></v-spacer>
        <v-row style="display: flex; flex-flow: row nowrap; gap: 1%">
          <v-text-field style="flex: 1 3 80px;" :min="1" type="number" v-model="nbShown" :label="$t('Patterns.PatternsPerPage')" outlined></v-text-field>
          <v-text-field style="flex: 3 1 80px;" v-model="search" :label="$t('General.Search')" outlined></v-text-field>
          <v-btn color="primary" @click="$router.push('Decos/new')">
            <v-icon left>mdi-plus</v-icon>
            {{ $t('Patterns.AddPattern') }}
          </v-btn>
        </v-row>
      </v-card-title>
      <v-select
        :items="sections"
        v-model="selectedSection"
        item-text="name"
        item-value="id"
        :label="$t('Patterns.Section')"
        outlined
        clearable
        @change="filterDecosBySection"
        style="margin-right: 75%;"
      ></v-select>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages" prev-icon="mdi-menu-left" next-icon="mdi-menu-right"></v-pagination>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-t="'General.Preview'" class="text-center"></th>
              <th v-t="'General.PublishingStatus'" class="text-center"></th>
              <th v-t="'Patterns.PatternName'" class="text-center"></th>
              <th class="text-center">
                {{ $t('General.Sections') }}
                <v-icon @click="sortDecosBySection('sections', 'asc')">mdi-arrow-up</v-icon>
                <v-icon @click="sortDecosBySection('sections', 'desc')">mdi-arrow-down</v-icon>
              </th>
              <th class="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="deco in shownDecos" :key="deco.id">
              <td>
                <img v-if="deco.svgPath !== ''" style="height: 100px; margin: 3px" :src="$axios.defaults.baseURL + '/downloadSvg?filename=' + deco.svgPath"/>
              </td>
              <td>
                <div style="display: flex; justify-content: center;">
                  <v-switch @change="modifyDeco(deco)" v-model="deco.published"></v-switch>
                </div>
              </td>
              <td>{{ deco.name }}</td>
              <td>
                <v-chip label v-if="deco.sectionListNames.length < 2">
                  {{ deco.sectionListNames.length > 0 ? deco.sectionListNames[0] : 'Aucune rubrique' }}
                </v-chip>
                <v-menu transition="slide-y-transition" open-on-hover offset-y v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip label v-bind="attrs" v-on="on">{{ deco.sectionListNames.length }} rubriques</v-chip>
                  </template>
                  <v-list>
                    <v-list-item v-for="sectionName in deco.sectionListNames" :key="sectionName + deco.id">
                      <v-list-item-content>{{ sectionName }}</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
              <td>
                <v-btn color="secondary" icon dark @click="$router.push('Decos/' + deco.id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <v-btn color="red" icon @click="openDeleteModal(deco)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
    </v-card>
    <DeleteModal ref="deleteModal" @confirm="deleteDeco" />
  </div>
</template>

<script>
import DeleteModal from '@/components/DeleteModal.vue'

  export default {
    name: 'Decos',
    components: {
      DeleteModal
    },
    data () {
      return {
        decos: [],
        currentPage: 1,
        loading: false,
        search: '',
        currentDecos: [],
        sections: [],
        nbShown: 20,
        selectedSection: '',
        itemToDelete: null
      }
    },
    async created () {
      await this.getDecos()
      await this.getSections()

      this.decos.forEach((deco) => {
        deco['sectionListNames'] = []
        this.sections.forEach((section) => {
          if (deco.sectionList.find((sectionId) => { return sectionId === section.id })) {
            deco['sectionListNames'].push(section.name)
          }
        })
      })
      this.selectedSection = ''
      this.currentDecos = this.decos
      this.selectedSection = this.sections[0] ? this.sections[0].id : ''
      this.filterDecosBySection()
    },
    computed: {
      shownDecos () {
        const startIndex = (this.currentPage - 1) * this.nbShown
        const endIndex = startIndex + this.nbShown
        return this.currentDecos.slice(startIndex, endIndex)
      },
      pages () {
        return Math.ceil(this.currentDecos.length / this.nbShown)
      }
    },
    watch: {
      search: function (val) {
        this.currentPage = 1
        this.applySearchFilter()
      },
      nbShown: function (val) {
        const numberShown = parseInt(val)
        this.nbShown = numberShown <= 0 ? 1 : numberShown
        this.currentPage = 1
      }
    },
    methods: {
      applySearchFilter () {
        const searchQuery = this.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        if (searchQuery === '') {
          this.currentDecos = this.decos
        } else {
          this.currentDecos = this.decos.filter((deco) => {
            return deco.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchQuery) ||
              deco.sectionListNames.find((section) => {
                return section.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(searchQuery)
              })
          })
        }
      },
      sortDecosBySection (sortBy, sortDesc) {
        if (sortBy === 'sections') {
          if (sortDesc === 'asc') {
            this.currentDecos.sort((a, b) => {
              return a.sectionListNames[0].localeCompare(b.sectionListNames[0])
            })
          } else {
            this.currentDecos.sort((a, b) => {
              return b.sectionListNames[0].localeCompare(a.sectionListNames[0])
            })
          }
        }
      },
      filterDecosBySection (sectionId) {
        if (!sectionId) {
          this.currentDecos = this.decos
        } else {
          this.currentDecos = this.decos.filter((deco) => {
            return deco.sectionList.includes(sectionId)
          })
        }
        if (this.search !== '') {
          this.currentDecos = this.currentDecos.filter((deco) => {
            return deco.name.toLowerCase().includes(this.search.toLowerCase())
          })
        }
      },
      openDeleteModal (deco) {
        this.itemToDelete = deco
        this.$refs.deleteModal.openModal()
      },
      deleteDeco () {
        if (!this.itemToDelete) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.NoItemSelectedToDelete')
          })
          return
        }
        this.$store.dispatch('deleteDeco', this.itemToDelete.id)
          .then(() => {
            this.currentDecos = this.currentDecos.filter(deco => deco.id !== this.itemToDelete.id)
            this.decos = this.decos.filter(deco => deco.id !== this.itemToDelete.id)
            this.$emit('notify', {
              color: 'green',
              text: this.$t('Messages.DeletionSuccess')
            })
          })
          .catch(() => {
            this.$emit('notify', {
              color: 'red',
              text: this.$t('Messages.DeletionFailed')
            })
          })
          .finally(() => {
            this.$refs.deleteModal.closeModal()
            this.itemToDelete = null
          })
      },
      async getDecos () {
        return this.$store.dispatch('getAllDecos').then(() => {
          this.decos = this.$store.getters['GET_DECOS']()
        })
      },
      async getSections () {
        return this.$store.dispatch('getAllSections').then(() => {
          this.sections = this.$store.getters['GET_SECTIONS']()
        })
      },
      modifyDeco (deco) {
        this.$store.dispatch('editDeco', deco).catch((err) => {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('Messages.' + err.response.data)
          })
        })
      }
    }
  }
</script>

<style scoped>
</style>
